import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Label",
  "type": "Content",
  "icon": "paletteLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Label`}</strong>{` is used for rendering a label or a tag to semantically enhance a section.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div>
  <Label>Default</Label>
  <Label variant="popular">Popular</Label>
  <Label variant="new">New</Label>
  <Label variant="featured">Featured</Label>
  <Label variant="soon">Coming Soon</Label>
  <div style={{ margin: "10px 0" }} />
  <Label ghost>Default</Label>
  <Label variant="popular" ghost>
    Popular
  </Label>
  <Label variant="new" ghost>
    New
  </Label>
  <Label variant="featured" ghost>
    Featured
  </Label>
  <Label variant="soon" ghost>
    Coming Soon
  </Label>
</div>
`}</code></pre>
    <h1>{`Props`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Label`}</strong>{` component passes all its props to the underlying `}<em parentName="p">{`div`}</em>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`default`}</inlineCode>{`, `}<inlineCode parentName="td">{`popular`}</inlineCode>{`, `}<inlineCode parentName="td">{`new`}</inlineCode>{`, `}<inlineCode parentName="td">{`featured`}</inlineCode>{`, `}<inlineCode parentName="td">{`soon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`soon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ghost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outline`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      